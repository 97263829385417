import {Reducer} from "redux";
import {
    GlobalActionType,
    GlobalState
} from "./globalTypes";

export const initialState: GlobalState = {
    loading: false,
}

export const GlobalReducer: Reducer<GlobalState> = (state = initialState, action) => {
    switch (action.type) {
        case GlobalActionType.SET_LOADING:
            const {loading} = action
            return {...state, loading}
        default:
            return state
    }
}