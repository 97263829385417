import { Reducer } from "redux";
import { ConnectionActionType, ConnectionState } from "./connectionTypes";

const createSweeetColor = () => {
    const randomSweetColors = [
        "#FFC312",
        "#C4E538",
        "#12CBC4",
        "#FDA7DF",
        "#ED4C67",
        "#F79F1F",
        "#A3CB38",
        "#1289A7",
        "#D980FA",
        "#B53471",
        "#EE5A24",
        "#009432",
        "#0652DD",
        "#9980FA",
        "#833471",
        "#EA2027",
        "#006266",
        "#1B1464",
        "#5758BB",
        "#6F1E51",
    ];

    return randomSweetColors[
        Math.floor(Math.random() * randomSweetColors.length)
    ];
};

export const initialState: ConnectionState = {
    id: undefined,
    loading: false,
    list: [],
    selectedId: undefined
}

export const ConnectionReducer: Reducer<ConnectionState> = (state = initialState, action) => {
    if (action.type === ConnectionActionType.CONNECTION_INPUT_CHANGE) {
        const { id } = action
        return { ...state, id }
    } else if (action.type === ConnectionActionType.CONNECTION_CONNECT_LOADING) {
        const { loading } = action
        return { ...state, loading }
    } else if (action.type === ConnectionActionType.CONNECTION_LIST_ADD) {
        let newList = [...state.list, {
            id: action.id,
            color: createSweeetColor()
        }]
        if (newList.length === 1) {
            return { ...state, list: newList, selectedId: action.id }
        }
        return { ...state, list: [...state.list, {
            id: action.id,
            color: createSweeetColor()
        }] }
    } else if (action.type === ConnectionActionType.CONNECTION_LIST_REMOVE) {
        let newList = [...state.list].filter(e => e.id !== action.id)
        if (state.selectedId && !newList.find(e => e.id === state.selectedId)) {
            if (newList.length === 0) {
                return { ...state, list: newList, selectedId: undefined }
            } else {
                return { ...state, list: newList, selectedId: newList[0].id }
            }
        }
        return { ...state, list: newList }
    } else if (action.type === ConnectionActionType.CONNECTION_ITEM_SELECT) {
        return { ...state, selectedId: action.id }
    } else {
        return state
    }
}