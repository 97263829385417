import Peer, {DataConnection} from "peerjs";
import {message} from "antd";

export enum DataType {
    FILE = 'FILE',
    OTHER = 'OTHER'

}
export interface Data {
    dataType: DataType
    file?: Blob | ArrayBuffer
    fileName?: string
    fileType?: string
    message?: string
}

let peer: Peer | undefined
let connectionMap: Map<string, DataConnection> = new Map<string, DataConnection>()

export const PeerConnection = {
    reconnectCount: 0,
    getNewId: () => {
        return Math.floor(Math.random() * 8999) + 1000 + ""
    },
    getPeer: () => peer,
    startPeerSession: () => new Promise<string>((resolve, reject) => {
        try {
            PeerConnection.reconnectCount++;
            const peerId = PeerConnection.getNewId();
            peer = new Peer(peerId)
            peer.on('open', (id) => {
                resolve(id)
            }).on('error', (err: any) => {
                console.dir(err)
                console.log(err.type)

                if (!err) return;

                if (err.type === "unavailable-id") {
                    const second = 1;
                    if (PeerConnection.reconnectCount > 5) {
                        reject(err)
                        message.error(`Your ID: ${peerId} is not available, please try again later`)
                        return
                    }

                    setTimeout(() => {
                        PeerConnection.startPeerSession()
                    }, second * 1000)

                    message.error(`Your ID: ${peerId} is not available, ${second} seconds to reconnect...`)
                } else if (err.type === "peer-unavailable") {
                    // message.error(`${connection.id} is not available, please try again later`)
                    message.error(err.message)
                } else {
                    message.error(err.message)
                    console.log("%c" + err.message, "color: red")
                }
            })
        } catch (err) {
            console.log(err)
            reject(err)
        }
    }),
    closePeerSession: () => new Promise<void>((resolve, reject) => {
        try {
            if (peer) {
                peer.destroy()
                peer = undefined
            }
            resolve()
        } catch (err) {
            console.log(err)
            reject(err)
        }
    }),
    connectPeer: (id: string) => new Promise<void>((resolve, reject) => {
        if (!peer) {
            reject(new Error("Peer doesn't start yet"))
            message.error("Connection doesn't start yet")
            return
        }

        if (connectionMap.has(id)) {
            reject(new Error("Connection existed"))
            message.error("Connection existed")
            return
        }

        try {
            let conn = peer.connect(id, {reliable: true})
            if (!conn) {
                reject(new Error("Connection can't be established"))
            } else {
                conn.on('open', function() {
                    console.log("Connect to: " + id)
                    connectionMap.set(id, conn)
                    resolve()
                }).on('error', function(err) {
                    console.log(err)
                    reject(err)
                })
            }
        } catch (err) {
            reject(err)
        }
    }),
    onIncomingConnection: (callback: (conn: DataConnection) => void) => {
        peer?.on('connection', function (conn) {
            console.log("Incoming connection: " + conn.peer)
            connectionMap.set(conn.peer, conn)
            callback(conn)
        });
    },
    onConnectionDisconnected: (id: string, callback: () => void) => {
        if (!peer) {
            throw new Error("Peer doesn't start yet")
        }
        if (!connectionMap.has(id)) {
            throw new Error("Connection didn't exist")
        }
        let conn = connectionMap.get(id);
        if (conn) {
            conn.on('close', function () {
                console.log("Connection closed: " + id)
                connectionMap.delete(id)
                callback()
            });
        }
    },
    sendConnection: (id: string, data: Data): Promise<void> => new Promise(async (resolve, reject) => {
        if (!connectionMap.has(id)) {
            reject(new Error("Connection didn't exist"))
        }
        try {
            let conn = connectionMap.get(id);
            if (conn) {
                console.log("id", id);
                console.log("data", data, conn);
                await conn.send(data)
            }
        } catch (err) {
            reject(err)
        }
        resolve()
    }),
    onConnectionReceiveData: (id: string, callback: (f: Data) => void) => {
        if (!peer) {
            throw new Error("Peer doesn't start yet")
        }
        if (!connectionMap.has(id)) {
            throw new Error("Connection didn't exist")
        }
        let conn = connectionMap.get(id)
        if (conn) {
            conn.on('data', function (receivedData) {
                console.log("Receiving data from " + id)
                let data = receivedData as Data
                callback(data)
            })
        }
    }

}